<template>
    <div class="flex flex-col mx-4 h-screen">    
        <div class="flex-auto">
            <img class="md:mx-20 my-4 h-28 md:h-32" :src="require('../../assets/logo.png')" />
        </div>
        <div class="flex-auto md:mx-20">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 md:col-span-12">
                    <p class="text-gray-800 font-normal text-xl md:text-2xl mb-16">
                        Agora sim, só precisamos dar uns <b>recadinhos</b> antes de você iniciar seu período de testes, então lá vai:
                    </p>
                    <p class="text-gray-800 font-normal text-xl md:text-2xl mb-4">
                        1) O seu período de testes é de 15 (quinze) dias
                    </p>
                    <p class="text-gray-800 font-normal text-xl md:text-2xl mb-4">
                        2) Quando quiser contratar, basta clicar em "contratar" lá nos planos
                    </p>
                    <p class="text-gray-800 font-normal text-xl md:text-2xl mb-4">
                        3) Você pode tirar dúvidas clicando em "tutoriais" lá na plataforma mesmo.
                    </p>
                    <p class="text-gray-800 font-normal text-xl md:text-2xl mb-4">
                        4) Para outras dúvidas, criticas ou sugestões (adoramos sugestões) clique em "suporte"
                    </p>

                    <p class="text-terciary font-semibold text-xl md:text-2xl mt-16">
                        Desejamos um excelente período de testes.
                    </p>
                    <p class="text-gray-800 font-semibold text-lg md:text-xl mt-2">
                        Equipe FÁCIL gestão LGPD

                    </p>
                </div>
            </div>
        </div>
        <div class="flex-auto items-end">
            <div class="float-left">
                <progress-bar class="md:ml-20" :options="options" :value="value"/> 
                <label class="md:ml-20 block text-base font-medium text-center">
                    Passo 4 de 4
                </label>
            </div>
            <button @click="$router.push({path:'/'})" type="button" class="float-right md:mr-10 transition duration-200 bg-yellow-400 hover:bg-yellow-500 focus:bg-yellow-800 focus:shadow-sm focus:ring-4 focus:ring-gray-800 focus:ring-opacity-50 text-white px-8 py-4 text-sm md:text-base shadow-sm hover:shadow-md font-semibold text-center inline-block">
                <span class="inline-block mr-2">Finalizar</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            consultoria: false, 
            empresa: false,
            form: {
                email: ''
            },
            value: 100,
            options: {
                text: {
                    color: '#FFFFFF',
                    shadowEnable: false,
                    shadowColor: '#000000',
                    fontSize: 14,
                    fontFamily: 'Helvetica',
                    dynamicPosition: false,
                    hideText: false
                },
                progress: {
                    color: '#FBBF24',
                    backgroundColor: '#CCCC',
                    inverted: false
                },
                layout: {
                    height: 30,
                    width: 140,
                    verticalTextAlign: 61,
                    horizontalTextAlign: 43,
                    zeroOffset: 0,
                    strokeWidth: 30,
                    progressPadding: 0,
                    type: 'line'
                }
            }
        }
    },
    methods: {
        async registrar () {
            if(this.$route.query && this.$route.query.consultoria) this.form.consultor = true;
            const resp = await this.$http.post("/registrar", this.form);
            if (!resp.data.success) {
                this.$vToastify.error(resp.data.err);
            } else {
                this.$router.push({path:`/cadastrar/${resp.data.data._id}`});
            }
        }
    }
}
</script>